<template>
    <div class="bg-box">
        <!-- 头部 -->
        <div class="header bgf">
            <div class="w-box">
                <div class="logo" @click="goPage('home')">
                    <img src="@/assets/img/else/logo.png" alt />
                </div>
                <div class="header-nav">
                    <div>我的工单</div>
                    <div @click="goPage('myOrder')">我的备案</div>
                    <div class="active">{{user}}</div>
                </div>
            </div>
        </div>

        <!-- 页面标题+面包屑 -->
        <div class="title-crumb bgf">
            <div class="w-box">
                <div class="page-title">备案信息列表</div>
                <!-- 搜索 -->
                <div class="search-box">
                    <div class="left-box">
                        <img src="@/assets/img/else/search.png" alt />
                        <input type="text" placeholder="请输入搜索信息内容" v-model="searchVal" />
                    </div>
                    <button type="button" @click="searchList">搜索</button>
                </div>
            </div>
        </div>

        <template v-if="orderArray.length != 0">
            <!-- 背景色标题卡片 -->
            <div class="bg-card" v-for="item in orderArray" :key="item.id" @click.prevent="goPage(item.id, item.status)">
                <div class="card-head">
                    <div class="order-title">
                        <div class="title-box">
                            <div class="id">订单号: {{item.order_number}}</div>
                            <div class="time">{{item.created_at | formatDate}}</div>
                        </div>
                        <div class="detail">订单详情</div>
                    </div>
                </div>
                <div class="card-content">
                    <div class="order-info">
                        <div class="info-title">
                            <div class="title">账号</div>
                            <div class="title">小程序主体</div>
                            <div class="title">小程序名称</div>
                            <div class="title">小程序ID</div>
                            <div class="title">状态</div>
                            <div class="title">备注</div>
                        </div>
                        <div class="info-content">
                            <div class="content">{{item.user_name}}</div>
                            <div class="content">{{item.type_name}}</div>
                            <div class="content">{{item.app_name}}</div>
                            <div class="content" v-if="item.detail">{{item.detail.app_id}}</div>
                            <div class="content" v-else>待填写</div>
                            <div class="content">
                                <div class="tx status-box d-flex align-items-center" v-if="item.status != 4">
                                    <img src="@/assets/img/else/tx.png" alt />
                                    <span class>{{item.status_text}}</span>
                                </div>
                                <div class="accomplish status-box d-flex align-items-center" v-if="item.status == 4">
                                    <img src="@/assets/img/else/accomplish.png" alt />
                                    <span class>{{item.status_text}}</span>
                                </div>
                            </div>
                            <div class="content">--</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="all-num">
                <div class="w-box">
                    <span>总{{orderArray.length}}条</span>
                </div>
            </div>

            <!-- 操作按钮 -->
            <div class="reviewing bgf">
                <div class="btn-box w-box">
                    <button class="draft-btn" type="button" @click="goPage('home')">返回上一级</button>
                    <button class="btn btn-primary save-btn linear-bg" type="button" @click="goPage('home')">申请新的备案</button>
                </div>
            </div>
        </template>

        <!-- 搜索结果为空 -->
        <div class="search-none w-box bgf" v-if="orderArray.length == 0">
            <div class="icon-box">
                <img src="@/assets/img/else/none.png" alt />
            </div>
            <div class="tips">暂无订单哦~</div>
            <div class="btn-box">
                <button class="new-btn" type="button" @click="goPage('home')">申请新的备案</button>
                <button class="back-btn" type="button" @click="goPage('home')">返回首页</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            begin: '',
            end: '',
            order: '',
            subject: '',
            app: '',
            appName: '',
            user: '',
            orderArray: [],
            searchVal: '',
            searchStatus: true, //是否有搜索结果
        }
    },
    methods: {
        // 获取订单列表
        getList() {
            let _token = this.$cookies.get('fm_token')

            this.$axios
                .post('/api/order/list', {
                    token: _token,
                })
                .then((res) => {
                    // console.log('res', res)
                    if (res.data.code == 200) {
                        this.orderArray = res.data.data.data.reverse()
                        // console.log('this.orderArray', this.orderArray)
                    }
                })
                .catch((err) => {
                    console.log('err', err)
                })
        },
        // 搜索备案
        searchList() {
            // console.log('搜索')
            let _token = this.$cookies.get('fm_token')

            this.$axios
                .post('/api/order/list', {
                    token: _token,
                    begin: this.begin,
                    end: this.end,
                    order: this.order,
                    subject: this.subject,
                    app: this.app,
                    user: this.searchVal,
                })
                .then((res) => {
                    // console.log('res', res)
                    if (res.data.code == 200) {
                        this.orderArray = res.data.data.data.reverse()
                    }
                })
                .catch((err) => {
                    // console.log('err', err)
                })
        },
        //跳转相应页面
        goPage(i, status) {
            if (i == 'home') {
                this.$router.push(`/${i}`)
                return
            }
            if (i == 'createOrder') {
                this.$router.push(`/${i}`)
                return
            }
            if (status == 0) {
                // 0未支付，跳转支付页
                // localStorage.setItem('orderIds', i) // 订单id存储
                this.$router.push({ path: '/payments', query: { id: i } })
            } else if (status == 1) {
                // 1已支付待填写，跳转信息填写页
                this.$router.push({ path: '/fillinfo', query: { id: i } })
            } else if (status == 2) {
                // 2已填写待验证，跳转认证页
                this.$router.push({ path: '/examine', query: { id: i } })
            } else if (status == 3) {
                // 3审核中，跳转详情页
                this.$router.push({ path: '/detailinfo', query: { id: i } })
            } else if (status == 4) {
                // 4已完成，跳转...（待定）
                this.$router.push('/myorder')
            }
        },
        goExm(path) {
            this.$router.push({ path: `/${path}`, query: { id: 41 } })
            if (path == 'serve') {
                this.$router.push(`/${path}`)
            }
        },
    },
    filters: {
        formatDate(dateTimeString) {
            const date = new Date(dateTimeString)
            const year = date.getFullYear()
            const month = String(date.getMonth() + 1).padStart(2, '0')
            const day = String(date.getDate()).padStart(2, '0')
            const hours = String(date.getHours()).padStart(2, '0')
            const minutes = String(date.getMinutes()).padStart(2, '0')
            const seconds = String(date.getSeconds()).padStart(2, '0')

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
        },
    },
    mounted() {
        // 进入页面后平滑至顶部
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // 使用平滑滚动效果
        })
    },
    created() {
        let user = localStorage.getItem('fm_user')
        this.user = user

        // 调用一次订单列表
        this.getList()
    },
    // //组件路由守卫判断用户是否登录
    // beforeRouteEnter(to, from, next) {

    //     // 获取cookie里的token
    //     let _token = this.$cookies.get('fm_token')

    //     //判断是否存在登录信息
    //     if (_token) {
    //         // 说明用户已登录
    //         this.$store.commit('setLoginStatus', true)
    //         this.$store.commit('setToken', _token)
    //         next()
    //     } else {
    //         // 用户未登录，重定向至登录页
    //         next({ path: '/login' })
    //     }
    // },
}
</script>

<style lang="scss" scoped>
$primary-color: #ffa842;

.bg-box {
    background-color: #f8f8f8;
    min-height: 100%;
}

.bgf {
    background-color: #fff;
}

// 头部
.header {
    height: 76px;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;

    .w-box {
        display: flex;
        justify-content: space-between;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
    }

    .header-nav {
        display: flex;
        align-items: center;

        > div {
            color: #767676;
            font-size: 14px;
            margin-right: 40px;
            cursor: pointer;
            user-select: none;

            &:last-child {
                margin-right: 0;
            }

            &.active {
                color: $primary-color;
            }
        }
    }
}

// 页面标题+面包屑
.title-crumb {
    height: 75px;

    .w-box {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .page-title {
        font-size: 20px;
        font-weight: bold;
    }

    .search-box {
        display: flex;
        align-items: center;

        .left-box {
            display: flex;
            align-items: center;
            border: 1px solid #efefef;
            border-right: none;
            width: 246px;
            height: 46px;
            box-sizing: border-box;
            overflow: hidden;
            padding-left: 14px;
            border-radius: 30px 0 0 30px;

            img {
                margin-right: 14px;
            }

            input {
                font-size: 14px;
                border: none;
                height: 46px;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }

                &::placeholder {
                    font-size: 12px;
                    color: #a3a3a3;
                }
            }
        }

        button {
            width: 96px;
            height: 46px;
            border: none;
            border-radius: 0 30px 30px 0;
            font-size: 14px;
            color: #fff;
            background-color: $primary-color;
        }
    }
}

// 背景色标题卡片
.bg-card {
    width: 1200px;
    margin: 32px auto 0;
    cursor: pointer;
    user-select: none;

    .card-head {
        background-color: #fff4e7;
        padding: 18px 30px;

        .order-title {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title-box {
                display: flex;
                align-items: center;

                .id {
                    font-weight: bold;
                    font-size: 14px;
                    margin-right: 22px;
                }

                .time {
                    font-size: 12px;
                    color: #717171;
                }
            }

            .detail {
                font-size: 14px;
                font-weight: bold;
                color: $primary-color;
            }
        }
    }
    .card-content {
        background-color: #fff;

        .order-info {
            .info-title {
                display: flex;
                font-size: 14px;
                border-bottom: 1px solid #f4f4f4;
                padding: 14px 0 14px 30px;

                .title {
                    font-weight: bold;

                    &:nth-child(1) {
                        width: 178px;
                    }
                    &:nth-child(2) {
                        width: 222px;
                    }
                    &:nth-child(3) {
                        width: 222px;
                    }
                    &:nth-child(4) {
                        width: 208px;
                    }
                    &:nth-child(5) {
                        width: 180px;
                    }
                    &:nth-child(6) {
                        width: 116px;
                    }
                }
            }
            .info-content {
                display: flex;
                font-size: 14px;
                padding: 14px 0 14px 30px;

                .content {
                    &:nth-child(1) {
                        width: 178px;
                    }
                    &:nth-child(2) {
                        width: 222px;
                    }
                    &:nth-child(3) {
                        width: 222px;
                    }
                    &:nth-child(4) {
                        width: 208px;
                    }
                    &:nth-child(5) {
                        width: 180px;
                    }
                    &:nth-child(6) {
                        width: 116px;
                    }

                    .status-box {
                        img {
                            margin-right: 7px;
                        }

                        span {
                            font-size: 12px;
                        }

                        &.tx {
                            color: $primary-color;
                        }

                        &.accomplish {
                            color: #38c98a;
                        }
                    }
                }
            }
        }

        .info-area {
            .inp-group {
                display: flex;
                margin-bottom: 32px;

                .info-list {
                    display: flex;
                    align-items: center;

                    &:first-child {
                        margin-right: 66px;

                        .lab {
                            width: 110px;
                        }
                    }

                    .lab {
                        width: 150px;
                        font-size: 14px;
                        color: #494949;
                        padding-left: 11px;
                    }

                    .inp {
                        width: 342px;
                        height: 44px;
                        background-color: #fafafa;
                        border-radius: 3px;
                        font-size: 14px;
                        padding: 0 0 0 15px;
                        border: none;

                        &::placeholder {
                            font-size: 12px;
                            color: #afafaf;
                        }

                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }
}

.all-num {
    font-size: 14px;
    color: #636363;
    text-align: right;
    margin-top: 28px;
    padding-bottom: 300px;
}

// 操作按钮
.reviewing {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 24px 0;
    border-top: 1px solid #f4f4f4;

    .btn-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            width: 171px;
            height: 58px;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1px;
            border-radius: 6px;
            color: #fff;
        }

        .draft-btn {
            margin-right: 12px;
            background-color: #acacac;
            border: none;
        }
    }
}

.search-none {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0 80px;

    .icon-box {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .tips {
        font-size: 14px;
        color: #8f8b8b;
    }

    .btn-box {
        display: flex;
        align-items: center;
        margin-top: 55px;

        button {
            width: 158px;
            height: 46px;
            box-sizing: border-box;
            border-radius: 30px;
            font-size: 14px;
            background-color: transparent;
        }

        .new-btn {
            margin-right: 10px;
            color: $primary-color;
            border: 1px solid $primary-color;
        }

        .back-btn {
            color: #9e9e9e;
            border: 1px solid #eeeeee;
        }
    }
}
</style>