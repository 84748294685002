<template>
    <div class="bg-box">
        <!-- 头部 -->
        <div class="header bgf">
            <div class="w-box">
                <div class="logo" @click="goPage('home')">
                    <img src="@/assets/img/else/logo.png" alt />
                </div>
                <div class="header-nav">
                    <div>我的工单</div>
                    <div @click="goPage('myOrder')">我的备案</div>
                    <div class="active">{{user}}</div>
                </div>
            </div>
        </div>

        <!-- 页面标题+面包屑 -->
        <div class="title-crumb bgf">
            <div class="w-box">
                <div class="page-title">待支付订单</div>
                <div class="crumb-box">
                    <div :class="['crumb',(index < 2) ? 'active':'']" v-for="item,index in crumbArray" :key="index">{{item.title}}</div>
                </div>
            </div>
        </div>

        <!-- 背景色标题卡片 -->
        <div class="bg-card">
            <div class="card-head">
                <div class="title-list">
                    <div class="title">订单号</div>
                    <div class="title">产品服务</div>
                    <div class="title">数量</div>
                    <div class="title">备注</div>
                    <div class="title">总额</div>
                </div>
            </div>
            <div class="card-content">
                <div class="content-box">
                    <div class="content">{{detailInfo.order_number}}</div>
                    <div class="content">{{detailInfo.goods_name}}</div>
                    <div class="content">1</div>
                    <div class="content">--</div>
                    <div class="content active">￥{{price}}</div>
                </div>
            </div>
        </div>

        <div class="bg-card">
            <div class="card-head">
                <div class="payment-title">
                    <div class="title">支付方式</div>
                    <div class="countdown-area">
                        <div class="price">
                            实付金额：
                            <span>￥{{detailInfo.price}}</span>
                        </div>
                        <div class="time-box">
                            <img src="@/assets/img/else/clock.png" alt />
                            <div class="time">
                                请在
                                <span>{{minute}}分{{second}}秒</span> 内完成支付哦
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-content c-mt">
                <div class="code-box">
                    <div class="code">
                        <div class="qr-code" v-if="qrCode">
                            <img :src="'https://bapi.cfumao.com'+qrCode" alt />
                            <!-- <img src="@/assets/img/else/qrcode.png" alt /> -->
                        </div>
                        <div class="title">微信扫码支付</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 支付失败模态框 -->
        <div class="mark_bg" v-if="isModel">
            <div class="content_box">
                <div class="tip_text">
                    支付超时
                    <br />请重新进入页面进行支付...
                </div>
                <div class="btn_box">
                    <div class="go_btn" @click="goPage('myOrder')">确定</div>
                </div>
            </div>
        </div>

        <!-- 支付成功模态框 -->
        <div class="success_modal" v-if="isSuccess">
            <div class="content_box">
                <img src="@/assets/img/else/pass.png" alt />
                <div class="tip_text">支付成功</div>
                <div class="btn_box">
                    <button type="button" class="btn btn-primary go_btn" @click="goInfo('fillinfo')">信息填写</button>
                    <button type="button" class="go_affirm" @click="goPage('myOrder')">返回列表</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            orderId: '',
            qrCode: '',
            order_number: '',
            price: '',
            paymentStatus: 500, //默认
            isPayment: false,
            paymentMsg: '',
            timer: '', //定时器
            isModel: false, //支付失败模态框
            isSuccess: false, //支付成功模态框
            code: '', //code码
            openId: '', //openid
            zfData: '', //支付数据
            detailInfo: '', //订单详情
            countdown: 900, // 倒计时总秒数，这里设置为15分钟的秒数

            user: '', //用户名

            crumbArray: [
                {
                    title: '确认订单',
                },
                {
                    title: '支付订单',
                },
                {
                    title: '信息填写',
                },
                {
                    title: '认证核验',
                },
                {
                    title: '完成',
                },
            ],
        }
    },
    methods: {
        // 调用查询接口
        inquire() {
            let _token = this.$cookies.get('fm_token')

            this.$axios
                .post('/api/pay/search', {
                    token: _token,
                    order: this.orderId,
                })
                .then((res) => {
                    // console.log('res', res)
                    this.paymentStatus = res.data.code
                })
        },

        //倒计时并查询支付状态
        startCountdown() {
            this.timer = setInterval(() => {
                this.countdown--
                // 调用接口查询支付状态
                this.inquire()

                // 获取当前返回的支付状态
                let paymentStatus = this.paymentStatus

                // console.log('当前支付状态码', paymentStatus)

                if (paymentStatus == 200 && this.countdown > 0) {
                    // 支付成功，清除定时器，退出循环，显示成功弹框
                    clearInterval(this.timer)
                    this.isSuccess = true
                } else if (this.countdown == 0) {
                    // 倒计时结束，支付未成功，清除定时器，并显示失败弹框
                    clearInterval(this.timer)
                    this.isModel = true
                }
            }, 1000)
        },

        // 获取订单详情
        getDetails() {
            let _token = this.$cookies.get('fm_token')

            this.$axios
                .post('/api/order/detail', {
                    token: _token,
                    order: this.orderId,
                })
                .then((res) => {
                    // console.log('resDetails', res)
                    if (res.data.code == 200) {
                        this.detailInfo = res.data.data
                        this.order_number = res.data.data.order_number
                        this.price = (res.data.data.price * 100) / 100
                    }
                    if (res.data.code == 500) {
                        // console.log(res.data.msg)
                    }
                })
        },

        //跳转页面
        goPage(path) {
            clearInterval(this.timer)
            this.$router.push({ path: `/${path}` })
        },

        //跳转
        goInfo(path) {
            // 跳转信息填写页
            clearInterval(this.timer)
            this.isSuccess = false
            this.$router.push({ path: `/${path}`, query: { id: this.orderId } })
        },
    },
    computed: {
        ...mapState(['isFromWeChat']), // 使用 mapState 辅助函数获取存储在vuex里的判断信息

        //分
        minute() {
            return String(Math.floor(this.countdown / 60)).padStart(2, '0')
        },
        //秒
        second() {
            return String(this.countdown % 60).padStart(2, '0')
        },
    },
    filters: {
        formatDate(dateTimeString) {
            const date = new Date(dateTimeString)
            const year = date.getFullYear()
            const month = String(date.getMonth() + 1).padStart(2, '0')
            const day = String(date.getDate()).padStart(2, '0')
            const hours = String(date.getHours()).padStart(2, '0')
            const minutes = String(date.getMinutes()).padStart(2, '0')
            const seconds = String(date.getSeconds()).padStart(2, '0')

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
        },
    },
    created() {
        // 获取订单ID
        this.orderId = this.$route.query.id
        console.log('当前订单id为：', this.orderId)

        let user = localStorage.getItem('fm_user')
        this.user = user

        // let _token = this.$cookies.get('fm_token')
        // this.$axios
        //     .post('/api/pay/get/openid/v2', {
        //         token: _token,
        //     })
        //     .then((res) => {
        //         console.log('test', res)
        //     })

        // 有订单id时执行
        if (this.orderId) {
            this.getDetails()
        }

        // 有订单id并且不是微信网页时执行
        if (this.orderId && !this.isFromWeChat) {
            let _token = this.$cookies.get('fm_token')

            // 获取支付二维码
            this.$axios
                .post('/api/pay/pc', {
                    token: _token,
                    order: this.orderId,
                })
                .then((res) => {
                    // console.log('qrcoderes', res)
                    if (res.data.code == 200) {
                        // console.log(res.data.msg)
                        this.qrCode = res.data.data.url.code_url
                    }
                    if (res.data.code == 500) {
                        // console.log(res.data.msg)
                        this.isPayment = true
                        this.paymentMsg = res.data.msg
                    }
                })
        }
    },
    mounted() {
        // 有订单id
        if (this.orderId) {
            // 调用查询支付
            this.startCountdown()
        }
    },
    // 如果有定时器,离开路由清除定时器
    beforeRouteLeave(to, from, next) {
        // 清除定时器
        if (this.timer) {
            clearInterval(this.timer)
        }
        next()
    },
    // 如果有定时器,离开页面时清除定时器
    beforeDestroy() {
        // 在组件销毁前清除定时器
        if (this.timer) {
            clearInterval(this.timer)
        }
    },
}
</script>

<style lang="scss" scoped>
$primary-color: #ffa842;

.bg-box {
    background-color: #f8f8f8;
    min-height: 100%;
}

.bgf {
    background-color: #fff;
}

// 头部
.header {
    height: 76px;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;

    .w-box {
        display: flex;
        justify-content: space-between;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
    }

    .header-nav {
        display: flex;
        align-items: center;

        > div {
            color: #767676;
            font-size: 14px;
            margin-right: 40px;
            cursor: pointer;
            user-select: none;

            &:last-child {
                margin-right: 0;
            }

            &.active {
                color: $primary-color;
            }
        }
    }
}

// 页面标题+面包屑
.title-crumb {
    height: 75px;

    .w-box {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .page-title {
        font-size: 20px;
        font-weight: bold;
    }

    .crumb-box {
        display: flex;

        .crumb {
            height: 26px;
            line-height: 26px;
            padding: 0px 12px 0 24px;
            font-size: 14px;
            color: #a1a1a1;
            margin-right: 14px;
            position: relative;
            background-color: #e3e3e3;

            &::before,
            &::after {
                position: absolute;
                content: '';
                width: 0;
                height: 0;
                border-top: 13px solid transparent;
                border-right: 12px solid transparent;
                border-bottom: 13px solid transparent;
                border-left: 12px solid transparent;
            }

            &::before {
                left: 0;
                top: 0;
                border-left-color: #fff;
            }

            &::after {
                right: -24px;
                top: 0;
                border-left-color: #e3e3e3;
            }

            &:first-child {
                padding-left: 12px;

                &::before {
                    content: none;
                }
            }

            &:last-child {
                margin-right: 0;
                &::after {
                    content: none;
                }
            }
        }

        .active {
            background-color: $primary-color;
            color: #fff;

            &::after {
                border-left-color: $primary-color;
            }
        }
    }
}

// 背景色标题卡片
.bg-card {
    width: 1200px;
    margin: 32px auto 0;

    .card-head {
        background-color: #fff4e7;
        padding: 18px 30px;

        .title-list {
            display: flex;

            .title {
                font-size: 14px;
                font-weight: bold;

                &:nth-child(1) {
                    width: 284px;
                }
                &:nth-child(2) {
                    width: 308px;
                }
                &:nth-child(3) {
                    width: 234px;
                }
                &:nth-child(4) {
                    width: 216px;
                }
                &:nth-child(5) {
                    width: 80px;
                }
            }
        }

        .payment-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 28px;

            .title {
                font-size: 14px;
                font-weight: bold;
            }

            .countdown-area {
                display: flex;
                align-items: center;
                font-size: 14px;

                .price {
                    display: flex;
                    align-items: center;
                    margin-right: 12px;
                    font-weight: bold;

                    span {
                        color: $primary-color;
                    }
                }
                .time-box {
                    display: flex;
                    align-items: center;

                    img {
                        margin-right: 3px;
                        margin-top: -1px;
                    }

                    span {
                        font-weight: bold;
                        margin: 0 2px;
                    }

                    .time {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
    .card-content {
        background-color: #fff;
        padding: 26px 58px 26px 30px;

        &.c-mt {
            margin-top: 23px;
        }

        .content-box {
            display: flex;

            .content {
                font-size: 14px;
                flex-shrink: 0;

                &.active {
                    color: $primary-color;
                }

                &:nth-child(1) {
                    width: 284px;
                }
                &:nth-child(2) {
                    width: 308px;
                }
                &:nth-child(3) {
                    width: 234px;
                }
                &:nth-child(4) {
                    width: 216px;
                }
                &:nth-child(5) {
                    width: 80px;
                }
            }
        }

        .code-box {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .code {
                display: flex;
                flex-direction: column;
                align-items: center;

                .qr-code {
                    display: flex;
                    align-items: center;
                    width: 200px;
                    height: 200px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .title {
                    font-size: 14px;
                    font-weight: bold;
                    margin-top: 8px;
                }
            }
        }
    }
}

.mark_bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    background-color: rgba($color: #000, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;

    .content_box {
        width: 568px;
        height: 356px;
        background-color: #fff;
        border-radius: 10px;
        padding: 60px 0 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .tip_text {
            margin: 12px 0 26px;
            text-align: center;
            font-weight: bold;
            font-size: 16px;
        }

        .btn_box {
            display: flex;
            align-items: center;
            justify-content: center;

            .go_btn {
                width: 130px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background-color: $primary-color;
                // font-size: 14px;
                letter-spacing: 1px;
                border-radius: 20px;
                color: #fff;
                cursor: pointer;
                user-select: none;
            }
        }
    }
}

// 支付成功模态框
.success_modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    background-color: rgba($color: #000, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;

    .content_box {
        width: 568px;
        height: 356px;
        background-color: #fff;
        border-radius: 10px;
        padding: 60px 0 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .tip_text {
            margin: 12px 0 46px;
            text-align: center;
            font-weight: bold;
            font-size: 16px;
        }

        .btn_box {
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                width: 130px;
                height: 40px;
                font-size: 14px;
                letter-spacing: 1px;
                border-radius: 20px;
                padding: 6px 0px;
                color: #fff;
                box-sizing: border-box;
                user-select: none;
            }

            .go_affirm {
                background-color: #fff;
                margin-left: 16px;
                border: 1px solid #c4c4c4;
                color: #535353;
            }
        }
    }
}
</style>