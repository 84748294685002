<template>
    <div class="bg-box">
        <!-- 头部 -->
        <div class="header bgf">
            <div class="w-box">
                <div class="logo" @click="goPage('home')">
                    <img src="@/assets/img/else/logo.png" alt />
                </div>
                <div class="header-nav">
                    <div>我的工单</div>
                    <div @click="goPage('myOrder')">我的备案</div>
                    <div class="active">{{user}}</div>
                </div>
            </div>
        </div>

        <!-- 页面标题+面包屑 -->
        <div class="title-crumb bgf">
            <div class="w-box">
                <div class="page-title">确认订单</div>
                <div class="crumb-box">
                    <div :class="['crumb',(index < 1) ? 'active':'']" v-for="item,index in crumbArray" :key="index">{{item.title}}</div>
                </div>
            </div>
        </div>

        <!-- 背景色标题卡片 -->
        <div class="bg-card">
            <div class="card-head">
                <div class="title-list">
                    <div class="title">套餐内容</div>
                    <div class="title">地域</div>
                    <div class="title">数量</div>
                    <div class="title">优惠金额</div>
                    <div class="title">价格</div>
                    <div class="title">备注</div>
                </div>
            </div>
            <div class="card-content">
                <div class="content-box">
                    <div class="content">{{sel_name}}</div>
                    <div class="content">广东</div>
                    <div class="content">1</div>
                    <div class="content" v-if="discounts">{{discounts}}</div>
                    <div class="content" v-else>0</div>
                    <div class="content active">￥{{sel_price}}</div>
                    <div class="content">--</div>
                </div>
            </div>
        </div>

        <form :class="['formorder','form','needs-validation',fromClass ? 'was-validated':'']" @submit.prevent="submitForm" novalidate>
            <div class="bg-card">
                <div class="card-head">
                    <div class="area-title">创建备案</div>
                </div>
                <div class="card-content">
                    <div class="info-area">
                        <div class="inp-group">
                            <div class="info-list">
                                <label class="form-label lab">用户名称</label>
                                <!-- <div>{{user}}</div> -->
                                <input type="text" class="form-control verify_input inp" required name="用户名" v-model="user" placeholder="请输入名称" disabled />
                            </div>
                            <div class="info-list">
                                <label class="form-label lab">
                                    主办单位/个人名称
                                    <span class="text-danger">*</span>
                                </label>
                                <input type="text" class="form-control verify_input inp" required name="主办单位或主办人名称" v-model="userName" placeholder="请填写主办单位/个人名称" />
                            </div>
                        </div>
                        <div class="inp-group">
                            <div class="info-list">
                                <label class="form-label lab">性质</label>
                                <div>
                                    <select class="form-select inp" name="性质" v-model="type">
                                        <option v-for="item in typeList" :key="item.id" :value="item.id">{{item.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="info-list">
                                <label class="form-label lab">
                                    小程序名称
                                    <span class="text-danger">*</span>
                                </label>
                                <input type="text" class="form-control verify_input inp" required name="小程序名称" v-model="appName" placeholder="请填写小程序名称" />
                            </div>
                        </div>
                        <div class="inp-group">
                            <div class="info-list">
                                <label class="form-label lab">优惠券</label>
                                <div>
                                    <select class="form-select inp" v-model="coupin" @change="selectCoupin()">
                                        <option v-for="item in coupinList" :key="item.id" :value="item.detail.id">无门槛券--抵扣{{(item.detail.amount * 100) / 100}}元</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="tips">
                            <span>温馨提示：</span>帐号名称长度为4-30个字符，一个中文字等于2个字符。请保持小程序名称的独特性以免与其他已注册小程序冲突。
                        </div>
                    </div>
                </div>
            </div>
            <div class="reviewing bgf">
                <div class="form-check check-box">
                    <input class="form-check-input check-inp" type="checkbox" id="flexCheckDefault1" v-model="ckOne" />
                    <label class="form-check-label reviewing-lab" for="flexCheckDefault1">
                        我已阅读并同意
                        <a href="#" @click.prevent="goPage('agreement')">《创孵猫代理备案服务协议》</a>
                        <a href="#" @click.prevent="goPage('statement')">《创孵猫代理备案服务信息收集及使用声明》</a>及同时授权本账号相关信息给创孵猫用于开展服务
                    </label>
                </div>
            </div>
            <div class="price-area bgf">
                <div class="w-box">
                    <div class="price-box">
                        应付款：
                        <div class="price">
                            ￥
                            <span class="c">{{sel_price}}</span>
                            <span class="e" v-if="suffix">.00</span>
                        </div>
                    </div>
                    <button type="submit" :class="ckOne == true ? 'active' : ''">立即支付</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fromClass: false, //from表单验证样式
            typeList: [], //性质列表
            type: 1, //默认性质类型
            userName: '', //用户名
            appName: '', //小程序名称
            code: '', //推荐码
            set_meal: [], //现有套餐
            radioVal: 1, //默认套餐
            ckOne: false, //是否同意声明
            inputs: [], //需要判断是否为空的input框
            user: '',
            sel_name: '', //现有套餐名字
            checkMeal: '', //已选套餐

            sel_price: '', //当前订单的价格
            count_price: '', //当前订单的价格(计算用)
            coupin: '', //优惠券ID
            coupinList: [], //优惠券列表
            discounts: '', //优惠券金额

            suffix: false, //小数点后两位

            crumbArray: [
                {
                    title: '确认订单',
                },
                {
                    title: '支付订单',
                },
                {
                    title: '信息填写',
                },
                {
                    title: '认证核验',
                },
                {
                    title: '完成',
                },
            ],
        }
    },
    methods: {
        // 提交表单
        submitForm() {
            // 调用检查表单的函数
            const emptyInput = this.checkEmptyInput()

            // console.log('emptyInput',emptyInput);

            // 如果函数返回，则平移至为空的input框前
            if (emptyInput) {
                this.fromClass = true //给bs表单加上提醒样式的类名
                emptyInput.scrollIntoView({
                    behavior: 'smooth', //平移
                    block: 'center', //居中显示
                })
            } else {
                // 判断是否同意声明和协议
                if (this.ckOne) {
                    // 下一步操作
                    // 提交表单逻辑
                    this.fromClass = false //清除bs表单的提醒样式
                    // 创建订单
                    let _token = this.$cookies.get('fm_token')
                    this.$axios
                        .post('/api/order/add', {
                            token: _token,
                            type: parseInt(this.type),
                            subject: this.userName,
                            app: this.appName,
                            code: this.code,
                            goods: parseInt(this.checkMeal) + 1,
                            coupon: this.coupin,
                        })
                        .then((res) => {
                            // console.log('res', res)
                            if (res.data.code == 200) {
                                // console.log(res.data)
                                // localStorage.setItem('orderIds', res.data.data.id) // 订单id存储
                                this.$router.push({ path: 'payments', query: { id: res.data.data.id } })
                            }
                            if (res.data.code == 500) {
                                console.log(res.data.msg)
                            }
                        })
                } else {
                    alert('请全部勾选声明协议')
                    return
                }
            }
        },
        // 检查是否有空的input框
        checkEmptyInput() {
            // 遍历检查是否有空的输入框
            for (let i = 0; i < this.inputs.length; i++) {
                if (this.inputs[i].value === '') {
                    return this.inputs[i]
                }
            }

            return null // 如果没有空的输入框
        },
        // 页面跳转
        goPage(path, i) {
            if (i) {
                this.$router.push({ path: `/${path}`, query: { id: i } })
                return
            }
            this.$router.push(`/${path}`)
        },
        // 获取优惠券
        getCoupin() {
            let _token = this.$cookies.get('fm_token')

            this.$axios
                .post('/api/coupon/list', {
                    token: _token,
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.coupinList = res.data.data
                        console.log('this.coupinList', this.coupinList)
                    }
                    if (res.data.code == 500) {
                        console.log(res.data.msg)
                    }
                })
        },
        // 选择优惠券
        selectCoupin() {
            // 获取优惠券ID
            console.log('this.coupin', this.coupin)
            // 获取优惠券金额
            let selectedOption = this.coupinList.find((option) => option.detail.id === this.coupin)
            console.log('优惠券金额', selectedOption.detail.amount)
            this.discounts = selectedOption.detail.amount
            let thisCoupin = Number(selectedOption.detail.amount)
            // 计算优惠后的价格
            let count_price = this.count_price
            let difference = count_price - thisCoupin
            let roundedResult = Math.ceil(difference * 100) / 100 // 向上取整保留两位小数
            roundedResult = roundedResult.toFixed(2) // 保留两位小数
            // 如果小数点后两位都是0，则不显示
            if (Number(roundedResult) === parseInt(roundedResult)) {
                this.sel_price = parseInt(roundedResult)
                this.suffix = true
            } else {
                this.sel_price = roundedResult
                this.suffix = false
            }
        },
    },
    // 钩子
    mounted() {
        //dom获取所有input框
        this.inputs = document.querySelectorAll('.verify_input')
    },
    created() {
        // 已选套餐
        this.checkMeal = this.$route.query.id

        // 企业性质
        const request1 = this.$axios.get('/api/type/list')

        // 商品列表
        const request2 = this.$axios.get('/api/goods/list')

        // 同时调用两个请求
        Promise.all([request1, request2]).then((res) => {
            // 两个请求现在都执行完成
            // console.log('res', res)

            this.typeList = res[0].data.data
            this.set_meal = res[1].data.data
            this.sel_name = res[1].data.data[this.checkMeal].name
            this.sel_price = (res[1].data.data[this.checkMeal].price * 100) / 100
            this.count_price = (res[1].data.data[this.checkMeal].price * 100) / 100
        })

        // 获取优惠券
        this.getCoupin()

        let user = localStorage.getItem('fm_user')
        this.user = user
    },
}
</script>

<style lang="scss" scoped>
$primary-color: #ffa842;

.bg-box {
    background-color: #f9f7f5;
    min-height: 100%;
}

// .was-validated .form-control:valid,
// .was-validated .form-select:valid {
//     border-color: var(--bs-border-color);
// }
// .was-validated .form-control:valid {
//     background-image: none;
// }
// .was-validated .form-select:valid:not([multiple]):not([size]) {
//     --bs-form-select-bg-icon: none;
// }

.bgf {
    background-color: #fff;
}

// 头部
.header {
    height: 76px;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;

    .w-box {
        display: flex;
        justify-content: space-between;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
    }

    .header-nav {
        display: flex;
        align-items: center;

        > div {
            color: #767676;
            font-size: 14px;
            margin-right: 40px;
            cursor: pointer;
            user-select: none;

            &:last-child {
                margin-right: 0;
            }

            &.active {
                color: $primary-color;
            }
        }
    }
}

// 页面标题+面包屑
.title-crumb {
    height: 75px;

    .w-box {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .page-title {
        font-size: 20px;
        font-weight: bold;
    }

    .crumb-box {
        display: flex;

        .crumb {
            height: 26px;
            line-height: 26px;
            padding: 0px 12px 0 24px;
            font-size: 14px;
            color: #a1a1a1;
            margin-right: 14px;
            position: relative;
            background-color: #e3e3e3;

            &::before,
            &::after {
                position: absolute;
                content: '';
                width: 0;
                height: 0;
                border-top: 13px solid transparent;
                border-right: 12px solid transparent;
                border-bottom: 13px solid transparent;
                border-left: 12px solid transparent;
            }

            &::before {
                left: 0;
                top: 0;
                border-left-color: #fff;
            }

            &::after {
                right: -24px;
                top: 0;
                border-left-color: #e3e3e3;
            }

            &:first-child {
                padding-left: 12px;

                &::before {
                    content: none;
                }
            }

            &:last-child {
                margin-right: 0;
                &::after {
                    content: none;
                }
            }
        }

        .active {
            background-color: $primary-color;
            color: #fff;

            &::after {
                border-left-color: $primary-color;
            }
        }
    }
}

// 背景色标题卡片
.bg-card {
    width: 1200px;
    margin: 32px auto 0;

    .card-head {
        background-color: #fff4e7;
        padding: 18px 30px;

        .title-list {
            display: flex;

            .title {
                font-size: 14px;
                font-weight: bold;

                &:nth-child(1) {
                    width: 284px;
                }
                &:nth-child(2) {
                    width: 178px;
                }
                &:nth-child(3) {
                    width: 178px;
                }
                &:nth-child(4) {
                    width: 206px;
                }
                &:nth-child(5) {
                    width: 197px;
                }
                &:nth-child(6) {
                    width: 78px;
                }
            }
        }

        .area-title {
            font-size: 14px;
            font-weight: bold;
        }
    }
    .card-content {
        background-color: #fff;
        padding: 26px 30px;

        .content-box {
            display: flex;

            .content {
                font-size: 14px;

                &.active {
                    color: $primary-color;
                }

                &:nth-child(1) {
                    width: 284px;
                }
                &:nth-child(2) {
                    width: 178px;
                }
                &:nth-child(3) {
                    width: 178px;
                }
                &:nth-child(4) {
                    width: 206px;
                }
                &:nth-child(5) {
                    width: 197px;
                }
                &:nth-child(6) {
                    width: 78px;
                }
            }
        }

        .info-area {
            .inp-group {
                display: flex;
                margin-bottom: 32px;

                .info-list {
                    display: flex;
                    align-items: center;

                    &:first-child {
                        margin-right: 66px;

                        .lab {
                            width: 110px;
                        }
                    }

                    .lab {
                        width: 150px;
                        font-size: 14px;
                        color: #494949;
                        padding-left: 11px;
                    }

                    .inp {
                        width: 342px;
                        height: 44px;
                        background-color: #fafafa;
                        border-radius: 3px;
                        font-size: 14px;
                        padding: 0 0 0 15px;
                        border: none;

                        &::placeholder {
                            font-size: 12px;
                            color: #afafaf;
                        }

                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }

                        &:-webkit-autofill {
                            background: transparent;
                            transition: background-color 50000s ease-in-out 0s;
                            -webkit-text-fill-color: unset;
                        }
                    }
                }
            }

            .tips {
                font-size: 14px;
                color: #727272;
                padding-left: 11px;

                span {
                    color: #494949;
                }
            }
        }
    }
}

.reviewing {
    width: 1200px;
    margin: 12px auto 0;
    padding: 22px 0 22px 40px;
    font-size: 14px;
    color: #7f7f7f;

    .check-box {
        display: flex;
        align-items: center;
        min-height: 0;
    }

    .check-inp {
        width: 18px;
        height: 18px;
        border-radius: 0;
        margin-top: 0;
        margin-right: 6px;
    }

    .reviewing-lab {
        display: flex;
        align-items: center;
        line-height: 18px;
    }

    a {
        text-decoration: none;
    }
}

.price-area {
    margin-top: 100px;
    padding: 26px 0;

    .w-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .price-box {
            display: flex;
            align-items: flex-end;
            font-size: 14px;
            font-weight: bold;

            .price {
                display: flex;
                align-items: flex-end;
                color: $primary-color;

                span.c {
                    font-size: 20px;
                    margin-bottom: -2px;
                }
            }
        }
        button {
            width: 146px;
            height: 52px;
            border: none;
            background-color: #fcc592;
            color: #fff;
            margin-left: 35px;
            border-radius: 5px;

            &.active {
                background-color: $primary-color;
            }
        }
    }
}
</style>